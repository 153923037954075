import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        author
        siteDesc: description
        image
        siteUrl
        siteTitle: title
        twitterUsername
      }
    }
  }
`;

const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(query);
  const { siteDesc, siteTitle, siteUrl, image, twitterUserName } = site.siteMetadata;

  return <Helmet htmlAttributes={{lang: "en" }} title={`${title} | ${siteTitle}`}>
    <meta name="description" content={ description || siteDesc } />
    <meta name="image" content={image} />
    {/* twitter cards */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:creator" content={twitterUserName} />
    <meta name="twitter:title" content={siteTitle} />
    <meta name="twitter:description" content={siteDesc} />
    <meta name="twitter:image" content={`${siteUrl}${image}`} />
  </Helmet>
}
export default SEO
